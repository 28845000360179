
const dateFormat = function(value,fmt){
    if(!value) return "";
    var date = new Date(value);
    var o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "H+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
        "q+": Math.floor((date.getMonth() + 3) / 3),
        "S": date.getMilliseconds()
    };
    fmt = fmt || "yyyy-MM-dd";
    if (/(y+)/.test(fmt))
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}
const formatFileSize = function(fileSize) {
    if (fileSize < 1024) {
        return fileSize + 'B';
    } else if (fileSize < (1024*1024)) {
        let temp = fileSize / 1024;
        temp = temp.toFixed(2);
        return temp + 'KB';
    } else if (fileSize < (1024*1024*1024)) {
        let temp = fileSize / (1024*1024);
        temp = temp.toFixed(2);
        return temp + 'MB';
    } else {
        let temp = fileSize / (1024*1024*1024);
        temp = temp.toFixed(2);
        return temp + 'GB';
    }
}
const numberToChinese = function(number){
    if(number == null || number == undefined) return '';
    /*
        单位
    */
    var units = '个十百千万@#%亿^&~';
    /*
        字符
    */
    var chars = '零一二三四五六七八九';
    /*
        数字转中文
        @number {Integer} 形如123的数字
        @return {String} 返回转换成的形如 一百二十三 的字符串            
    */
     var  a=(number+ '' ).split( '' ),s=[] ;
     if (a.length>12){
         throw  new  Error( 'too big' );
     } else {
         for ( var  i=0,j=a.length-1;i<=j;i++){
             if (j==1||j==5||j==9){ //两位数 处理特殊的 1*
                 if (i==0){
                     if (a[i]!= '1' )s.push(chars.charAt(a[i]));
                 } else {
                     s.push(chars.charAt(a[i]));
                 }
             } else {
                 s.push(chars.charAt(a[i]));
             }
             if (i!=j){
                 s.push(units.charAt(j-i));
             }
         }
     }
     //return s;
     return  s.join( '' ).replace(/零([十百千万亿@ #%^&~])/g,function(m,d,b){//优先处理 零百 零千 等
         b = units.indexOf(d);
         if (b!=-1){
             if (d== '亿' ) return  d;
             if (d== '万' ) return  d;
             if (a[j-b]== '0' ) return  '零'
         }
         return  '' ;
     }).replace(/零+/g, '零' ).replace(/零([万亿])/g, function (m,b){ // 零百 零千处理后 可能出现 零零相连的 再处理结尾为零的
         return  b;
     }).replace(/亿[万千百]/g, '亿' ).replace(/[零]$/, '' ).replace(/[@ #%^&~]/g,function(m){
         return  { '@' : '十' , '#' : '百' , '%' : '千' , '^' : '十' , '&' : '百' , '~' : '千' }[m];
     }).replace(/([亿万])([一-九])/g, function (m,d,b,c){
         c=t.units.indexOf(d);
         if (c!=-1){
             if (a[j-c]== '0' ) return  d+ '零' +b
         }
         return  m;
     });
 }
const copy = function(text){
    // navigator clipboard 需要https等安全上下文
    //ios终端
    let u = navigator.userAgent;
    if (!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) && navigator.clipboard && window.isSecureContext) {
        // navigator clipboard 向剪贴板写文本
        return navigator.clipboard.writeText(text);
    } else {
        // 创建text area
        let textArea = document.createElement("textarea");
        textArea.value = text;
        // 使text area不在viewport，同时设置不可见
        textArea.style.position = "absolute";
        textArea.style.opacity = 0;
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((resolve, reject) => {
            // 执行复制命令并移除文本框
            document.execCommand('copy') ? resolve() : reject();
            textArea.remove();
        });
    }
}
module.exports = {
    dateFormat,
    formatFileSize,
    numberToChinese,
    copy
}