import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const userInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : null;
const leagueInfo = sessionStorage.getItem('leagueInfo') ? JSON.parse(sessionStorage.getItem('leagueInfo')) : null;
const cardsMastersInfo = sessionStorage.getItem('cardsMastersInfo') ? JSON.parse(sessionStorage.getItem('cardsMastersInfo')) : null;

const state = {
  userInfo,
  leagueInfo,
  cardsMastersInfo
}
const mutations = {
  setUserInfo(state,obj){
    state.userInfo = obj;
    sessionStorage.setItem('userInfo',JSON.stringify(obj));
  },
  removeUserInfo(state){
    state.userInfo = null;
    sessionStorage.removeItem('userInfo');
  },
  setLeagueInfo(state,obj){
    state.leagueInfo = obj;
    sessionStorage.setItem('leagueInfo',JSON.stringify(obj));
  },
  removeLeagueInfo(state){
    state.leagueInfo = null;
    sessionStorage.removeItem('leagueInfo');
  },
  setCardsMastersInfo(state,obj){
    state.cardsMastersInfo = obj;
    sessionStorage.setItem('cardsMastersInfo',JSON.stringify(obj));
  },
  removeCardsMastersInfo(state){
    state.cardsMastersInfo = null;
    sessionStorage.removeItem('cardsMastersInfo');
  }
}
const getters = {
  cardsMastersInfo: state=>{
      return state.cardsMastersInfo || {};
  }
}
export default new Vuex.Store({
  state,
  mutations,
  getters
})
