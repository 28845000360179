import Vue from "vue"
import router from "@/router"

//设置title
Vue.directive('title',(el,binding)=>{
    document.title = binding.value;
})
//跳转页面
Vue.directive('href',{
    bind: function(el,binding) {
        if(binding.value){
            el.href = binding.value;
            el.onclick = function() {
                router.push(this.href);
            }
        }
    },
    update:function(el,binding){
        el.href = binding.value;
    }
})