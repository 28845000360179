import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';
Vue.use(VueRouter)

const Login = ()=>import('@/views/common/Login');//登录
const Main = ()=>import('@/views/common/Main');//主框架
const SelectLeague = ()=>import('@/views/common/SelectLeague');//选择切换主赛事
const UpdateMainEvent = ()=>import('@/views/common/UpdateMainEvent');//修改主赛事信息

const Home = ()=>import('@/views/Home');
//赛事管理
const CompetitionList = ()=>import('@/views/Match/CompetitionList');//赛事列表
const CompetitionDetail = ()=>import('@/views/Match/CompetitionDetail');//添加、修改赛事及历史赛事
const LookCompetitionDetail = ()=>import('@/views/Match/LookCompetitionDetail');//查看赛事及历史赛事信息
const SeasonRecheckDetail = ()=>import('@/views/Match/SeasonRecheckDetail');//赛事复审
const LookSeasonRecheckDetail = ()=>import('@/views/Match/LookSeasonRecheckDetail');//查看赛事复审信息
const ImportEnroll = ()=>import('@/views/Match/ImportEnroll');//上传报名册
const CompetitionResults = ()=>import('@/views/Match/CompetitionResults');//比赛成绩
const ImportResults = ()=>import('@/views/Match/ImportResults');//上传成绩册
const ScoreRegistration = ()=>import('@/views/Match/ScoreRegistration');//按报名表录入成绩
//报名管理
const EnrollList = ()=>import('@/views/Enroll/EnrollList');//报名列表
const EnrollDetail = ()=>import('@/views/Enroll/EnrollDetail');//添加修改报名设置
const LookEnrollDetail = ()=>import('@/views/Enroll/LookEnrollDetail');//查看报名设置信息
const EnrollAuditList = ()=>import('@/views/Enroll/EnrollAuditList');//报名审核列表
const EnrollAuditDetail = ()=>import('@/views/Enroll/EnrollAuditDetail');//报名参赛信息审核

const routes = [
  {
    path:"/Login",
    name:"Login",
    component:Login
  },{
    path:"/SelectLeague",
    name:"SelectLeague",
    component:SelectLeague
  },
  {
    path:"/",
    name:"Main",
    component:Main,
    children:[
      {
        path:'/',
        redirect:'/Match'
      },
      {
        path:'Home',
        name:'Home',
        component:Home
      },
      {
        path:'UpdateMainEvent',
        name:'UpdateMainEvent',
        component:UpdateMainEvent
      }
    ]
  },
  {
    path:'/Match',
    name:'Match',
    component:Main,
    children:[
      {
        path:'/',
        redirect:'/Match/CompetitionList'
      },
      {
        path:'CompetitionList',
        name:'CompetitionList',
        component:CompetitionList
      },
      {
        path:'CompetitionDetail/:subLeagueId?/:type?',
        name:'CompetitionDetail',
        component:CompetitionDetail
      },{
        path:'LookCompetitionDetail/:subLeagueId',
        name:'LookCompetitionDetail',
        component:LookCompetitionDetail
      },
      {
        path:'SeasonRecheckDetail/:seasonId/:type?',
        name:'SeasonRecheckDetail',
        component:SeasonRecheckDetail
      },{
        path:'LookSeasonRecheckDetail/:seasonId',
        name:'LookSeasonRecheckDetail',
        component:LookSeasonRecheckDetail
      },{
        path:'CompetitionResults/:subLeagueId/:type?',
        name:'CompetitionResults',
        component:CompetitionResults
      },{
        path:'ImportResults/:subLeagueId',
        name:'ImportResults',
        component:ImportResults
      },{
        path:'ScoreRegistration/:subLeagueId',
        name:'ScoreRegistration',
        component:ScoreRegistration
      },{
        path:'ImportEnroll/:subLeagueId',
        name:'ImportEnroll',
        component:ImportEnroll
      }
    ]
  },{
    path:'/Enroll',
    name:'Enroll',
    component:Main,
    children:[
      {
        path:'/',
        redirect:'/Enroll/EnrollList'
      },{
        path:'EnrollList',
        name:'EnrollList',
        component:EnrollList
      },{
        path:'EnrollDetail/:leagueEnrollId?/:subLeagueId?/:type?',
        name:'EnrollDetail',
        component:EnrollDetail
      },{
        path:'LookEnrollDetail/:leagueEnrollId/:subLeagueId',
        name:'LookEnrollDetail',
        component:LookEnrollDetail
      },{
        path:'EnrollAuditList',
        name:'EnrollAuditList',
        component:EnrollAuditList
      },{
        path:'EnrollAuditDetail/:subLeagueId/:type?',
        name:'EnrollAuditDetail',
        component:EnrollAuditDetail
      }
    ]
  },
  {
    path:'*'
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if(to.path == from.path)return;
  if(to.path != '/Login' && !store.state.userInfo){
    next('/Login');
  }else if(to.path != '/Login' && to.path != '/SelectLeague' && !store.state.leagueInfo){
    next('/SelectLeague');
  }else{
    next();
  }
});
export default router
