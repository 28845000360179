import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import "@/assets/js/directives"
import "@/assets/js/filters"
import Axios from 'axios'
Vue.use(ElementUI)
Vue.config.productionTip = false;
Vue.prototype.window = window;
(async function(){
  let configData = {baseUrl:"/api/"};
  if(process.env.NODE_ENV == "production"){
    try{
        let res = await Axios.get('/config.json');
        if(res.status == 200) configData = res.data;
    }catch{
      configData = {baseUrl:"/"};
    }
  }
  Axios.defaults.baseURL = configData.baseUrl;
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app');
})()